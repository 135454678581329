<template>
  <div class="bio-block">
    <div class="bio-text">
      <p class="title">{{ biography.title }}</p>

      <p>
        I commenced my career as a fashion photographer, eventually transitioning into documentary film and photography
        for some of the foremost publications and media channels in Latin America. My interest in coding for the visual
        arts led me to Spain, where I have had the honor of working on on remarkable projects with esteemed brands such
        as Gucci, Remy Martin, and Alfa Romeo, among others.
      </p>

      <p>
        My work has been featured in publications such as
        <a href="https://aestheticamagazine.com/" target="_blank">Aesthetica Magazine</a>
        and The Black and White Collection Book by
        <a href="https://www.lensculture.com/" target="_blank">Exposure Awards</a>. My work has been on view as part of
        the Stories Within Images exhibition at
        <a href="https://artbasel.com/miami-beach" target="_blank">Art Basel Miami</a>.
      </p>

      <p>
        In 2014, I co-founded
        <a href="https://vimeo.com/baddstories" target="_blank">Baddstories</a>, a Barcelona based creative film
        company, garnering numerous nominations and awards.
      </p>
    </div>
    <div class="bio-image-frame">
      <div class="bio-image">
        <div class="image-wrapper"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      biography: {
        title: 'Bio',
        description: 'Alejandra Gomez Sarmiento',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.bio-block {
  border-bottom: 1px solid white;
  display: grid;
  grid-template-columns: 1fr;

  @media only screen and (min-width: $tbLandscape) {
    grid-template-columns: 1fr 1fr;
  }
}

.bio-text {
  align-content: center;
  background: $darkGrey;
  color: white;
  display: grid;
  text-align: justify;
  padding: 4rem;

  @media only screen and (min-width: $tbPortrait) {
    padding: 20rem 6rem 20rem 4rem;
  }

  @media only screen and (min-width: $tbLandscape) {
    justify-content: end;
  }

  .title {
    font-weight: 500;
    letter-spacing: 0.6rem;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
  }

  p {
    color: $offWhite;
    font-size: 1.4rem;

    @media only screen and (min-width: $tbLandscape) {
      max-width: 50rem;
    }
  }
}

.bio-image-frame {
  background: $parmaPink;
  padding: 5rem;

  @media only screen and (min-width: $tbLandscape) {
    background: $offWhite;
    padding: 20rem 0;
  }
}

.bio-image {
  background: white;
  display: grid;
  padding: 4rem;

  @media only screen and (min-width: $tbLandscape) {
    background: $offWhite;
  }

  .image-wrapper {
    align-self: center;
    background: url('~@/assets/images/ALEJANDRA.jpg') no-repeat center;
    background-size: contain;
    border-radius: 9rem;
    justify-self: center;
    height: 18rem;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
    width: 18rem;

    @media only screen and (min-width: $tbPortrait) {
      border-radius: 12rem;
      height: 24rem;
      width: 24rem;
    }

    @media only screen and (min-width: $tbLandscape) {
      border-radius: 25rem;
      height: 50rem;
      width: 50rem;
    }
  }
}
</style>
