<template>
  <div class="container">
    <HeroSection headTitle="Contact" />
    <SeparatorComponent sectionTitle="Inquiries" />

    <div class="content-holder contact">
      <p>
        For inquiries or to discuss potential collaborations, please feel free to reach out to me at
        <b>alejandragsarmiento@gmail.com</b>. I look forward to connecting with you.
      </p>
    </div>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import SeparatorComponent from '@/components/SeparatorComponent.vue';

export default {
  name: 'ContactView',
  components: {
    HeroSection,
    SeparatorComponent,
  },
};
</script>

<style lang="scss" scoped>
a.button {
  text-transform: none;
}
a {
  background: $darkGrey;
  cursor: pointer;
  font-size: 12px;
  letter-spacing: 1px;

  &:hover {
    background: $offWhite;
    color: black;
  }
}
</style>
