<template>
  <svg width="100" height="100" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 3C8.832 3 3 8.832 3 16v18c0 7.168 5.832 13 13 13h18c7.168 0 13-5.832 13-13V16c0-7.168-5.832-13-13-13H16zm0 2h18c6.087 0 11 4.913 11 11v18c0 6.087-4.913 11-11 11H16C9.913 45 5 40.087 5 34V16C5 9.913 9.913 5 16 5zm21 6a2 2 0 0 0-2 2 2 2 0 0 0 2 2 2 2 0 0 0 2-2 2 2 0 0 0-2-2zm-12 3c-6.063 0-11 4.937-11 11s4.937 11 11 11 11-4.937 11-11-4.937-11-11-11zm0 2c4.982 0 9 4.018 9 9s-4.018 9-9 9-9-4.018-9-9 4.018-9 9-9z"
      font-family="sans-serif"
      font-weight="400"
      overflow="visible"
      style="
        block-progression: tb;
        isolation: auto;
        mix-blend-mode: normal;
        text-decoration-color: #000;
        text-decoration-line: none;
        text-decoration-style: solid;
        text-indent: 0;
        text-transform: none;
      "
    />
  </svg>
</template>

<script>
export default {
  name: 'InstagramIcon',
};
</script>
