<template>
  <div id="app">
    <NavHeader />
    <router-view />
  </div>
</template>

<script>
import NavHeader from '@/components/NavHeader.vue';
// import SocialFooter from '@/components/SocialFooter.vue';
export default {
  components: {
    NavHeader,
    // SocialFooter,
  },
};
</script>

<style lang="scss">
@import './assets/scss/styles';

#app {
  background: transparent;
}

a.router-link-active {
  opacity: 0.4;

  &:hover {
    text-decoration: none;

    @media only screen and (min-width: $tbPortrait) {
      text-decoration: line-through;
    }
  }
}
</style>
