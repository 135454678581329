import { render, staticRenderFns } from "./SeparatorComponent.vue?vue&type=template&id=3ceb13fc&scoped=true&"
import script from "./SeparatorComponent.vue?vue&type=script&lang=js&"
export * from "./SeparatorComponent.vue?vue&type=script&lang=js&"
import style0 from "./SeparatorComponent.vue?vue&type=style&index=0&id=3ceb13fc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ceb13fc",
  null
  
)

export default component.exports