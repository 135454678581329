<template>
  <div class="container">
    <HeroSection headTitle="LegalView Notice" />

    <div class="content-holder">
      <p>
        No part of this work may be reproduced, distributed, or transmitted in any form or by any means, electronic or
        mechanical, including photocopying, downloading or otherwise, nor may it be stored in a database or hard drive
        without the prior written permission of the author and copyright holder.
      </p>
    </div>
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';

export default {
  name: 'LegalView',
  components: {
    HeroSection,
  },
};
</script>

<style lang="scss" scoped>
p {
  font-style: italic;
}
</style>
