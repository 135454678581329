<template>
  <div class="statement-wrapper">
    <p class="title">{{ statement.title }}</p>
    <p>
      My artistic oeuvre encompasses code, digital photography, collage, and image compositing, with a profound
      exploration of perceptual shifts. I am deeply engaged with themes of self-perception, identity formation in
      relation to the 'other,' and the subsequent construction of our surrounding reality.
    </p>
    <p>
      My inspirations include the pioneering works of Lillian F. Schwartz, the conceptual clarity of Sol LeWitt, the
      incisive critiques of Martha Rosler, the organic forms of Georgia O’Keefe, the generative artistry of Casey Reas,
      the innovative visualizations of Aaron Koblin, and the evocative compositions of H. P. Robinson.
    </p>
    <p>
      In my creative process, I am anchored in my home studio situated a few kilometers from Barcelona. My approach
      harmonizes the discipline of conceptual sketching with the serendipity that arises from engaging with diverse
      objects and landscapes, allowing for a dynamic interplay between intention and chance.
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      statement: {
        title: 'Statement',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.statement-wrapper {
  align-content: center;
  background: $offWhite;
  color: $grey;
  display: grid;
  height: 90rem;
  justify-content: center;
  padding: 4rem;
  text-align: justify;

  .title {
    color: $darkGrey;
    font-weight: 500;
    letter-spacing: 0.6rem;
    margin-bottom: 2rem;
    text-align: left;
    text-transform: uppercase;
  }

  p {
    font-size: 1.4rem;
    max-width: 55rem;
  }

  .quote {
    font-style: italic;
  }
}
</style>
