<template>
  <div class="separator" :class="{ topAnim: topAnim }">
    <div class="line-holder">
      <p class="section-title">{{ sectionTitle }}</p>
      <div class="line horizontal"></div>
      <div class="line vertical measure"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      topAnim: false,
    };
  },
  mounted() {
    let measure = document.querySelector('.measure');

    // https://forum.vuejs.org/t/calling-a-function-inside-another-function-in-methods/17697
    let _this = this;

    window.addEventListener('scroll', function () {
      if (_this.isInViewport(measure)) {
        _this.topAnim = true;
      } else {
        _this.topAnim = false;
      }
    });
  },
  methods: {
    // https://codepen.io/bfintal/pen/Ejvgrp?editors=0010
    isInViewport: function (elem) {
      const scroll = window.scrollY || window.pageYOffset;
      const boundsTop = elem.getBoundingClientRect().top + scroll;

      const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
      };

      const bounds = {
        top: boundsTop,
        bottom: boundsTop + elem.clientHeight,
      };
      return (
        (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.separator {
  background: $offWhite;
  display: grid;
  height: 60vh;
}

.line-holder {
  align-self: center;
  display: grid;
  justify-self: center;

  p {
    line-height: 3.4rem;
    max-width: 30rem;

    @media only screen and (min-width: $tbPortrait) {
      max-width: unset;
    }
  }
}

.section-title {
  font-size: 2rem;
  font-style: italic;
  letter-spacing: 0.6rem;
  opacity: 0;
  text-align: center;
  text-transform: uppercase;
  transform: translateX(-50px);
  transition: all 1s ease-in-out;
}

.line {
  align-self: center;
  justify-self: center;

  &.horizontal {
    border-top: 1px solid $lightGrey;
    height: 1rem;
    margin-bottom: 2rem;
    opacity: 0;
    transform: translateX(-50px);
    transition: all 1s ease-in-out 0.2s;
    width: 24rem;
  }

  &.vertical {
    border-right: 1px solid $lightGrey;
    height: 8rem;
    opacity: 0;
    transform: translateY(50px);
    transition: all 1s ease-in-out 0.2s;
    width: 1rem;
  }
}

.topAnim {
  .section-title {
    opacity: 1;
    transform: translateX(0px);
  }

  .line {
    &.horizontal {
      opacity: 1;
      transform: translateX(0px);
    }

    &.vertical {
      opacity: 1;
      transform: translateY(0px);
    }
  }
}
</style>
