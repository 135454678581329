<template>
  <div class="container">
    <HeroSection headTitle="Page Not Found" />

    <!-- <div class="content-holder">
      <p>You may want to go back</p>
      <router-link class="button" to="/">Home</router-link>
    </div> -->
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';

export default {
  name: 'PageNotFound',
  components: {
    HeroSection,
  },
};
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>
