<template>
  <div class="thumbs-grid">
    <p class="title">{{ sectionTitle }}</p>
    <div class="media">
      <div class="thumbnail">
        <span class="narrow-view-feature-title">The Exposure Award</span>

        <a href="https://vimeo.com/137868105" target="_blank">
          <div class="image-wrapper exposure"></div>

          <div class="featured-caption">
            <span class="feature-title">The Exposure Award</span>
            <span class="featured-background"></span>
          </div>
        </a>
      </div>
      <div class="thumbnail">
        <span class="narrow-view-feature-title">Aesthetica Magazine</span>

        <a href="https://aestheticamagazine.com" target="_blank">
          <div class="image-wrapper aesthetica-2022"></div>

          <div class="featured-caption">
            <span class="feature-title">Aesthetica Magazine</span>
            <span class="featured-background"></span>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sectionTitle: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      media: [
        {
          featureTitle: 'Aesthetica Magazine',
          featureImage: require('@/assets/images/aesthetica-magazine.jpg'),
          featureUrl: 'http://aestheticamagazine.com',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  a {
    background: black;
    color: black;
    display: inline;
  }

  &:hover {
    img {
      transform: scale(1);
    }
  }

  @media only screen and (min-width: $tbPortrait) {
    &:hover {
      img {
        transform: scale(0.9);
      }
    }
  }
}

.narrow-view-feature-title {
  display: block;
  font-size: 1.2rem;
  font-style: italic;
  letter-spacing: 0.6rem;
  margin: 3rem 0 1.5rem 0;
  text-align: center;
  text-transform: uppercase;

  @media only screen and (min-width: $tbPortrait) {
    display: none;
  }
}

.image-wrapper {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 23.3rem;
  width: 35rem;
  transition: all 0.3s ease-in-out;

  &.exposure {
    background-image: url('~@/assets/images/the-exposure-award.jpg');
  }

  &.aesthetica {
    background-image: url('~@/assets/images/aesthetica-magazine.jpg');
  }

  &.aesthetica-2022 {
    background-image: url('~@/assets/images/aesthetica-magazine-2022.jpg');
  }

  @media only screen and (min-width: $tbLandscape) {
    height: 30rem;
    width: 45rem;
  }

  @media only screen and (min-width: $wideScreen) {
    height: 40.4rem;
    width: 60rem;
  }
}

.featured-caption {
  display: none;

  .feature-title {
    font-weight: 500;
    letter-spacing: 0.6rem;
    margin-bottom: 2rem;
    text-transform: uppercase;
  }

  @media only screen and (min-width: $tbPortrait) {
    display: grid;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: all 0.4s ease-in-out;
    width: 100%;

    span {
      display: inline-block;
      font-size: 1rem;
      font-style: italic;
      justify-self: center;
      letter-spacing: 0.1rem;
      margin-bottom: 0;
      text-transform: uppercase;
    }

    .feature-title {
      align-self: center;
      z-index: 1;
    }

    .featured-background {
      background: white;
      height: 100%;
      opacity: 0.8;
      position: absolute;
      width: 100%;
    }

    &:hover {
      opacity: 1;
      transform: scale(1.3);
    }
  }
}
</style>
