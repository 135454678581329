<template>
  <div class="home container">
    <HeroSection class="home-hero-title" headTitle="Alejandra Gomez Sarmiento" />

    <!-- <SeparatorComponent sectionTitle="ArtWork" />

    <SubtleBreathOfLifeTwo
      sectionTitle="Subtle Breath Of Life II"
      sectionSubTitle="GLSL on Photo Composite"
      yearOfArtwork="2020"
    />

    <SubtleBreathOfLifeOne
      sectionTitle="Subtle Breath Of Life I"
      sectionSubTitle="Photo Composite"
      yearOfArtwork="2019"
    />

    <HomageSection sectionTitle="Homage to Martha Rosler" sectionSubTitle="Photo Composite" yearOfArtwork="2017" />

    <SingleWorks sectionTitle="Single Works" sectionSubTitle="Photo Composite" yearOfArtwork="2004 - 2010" />

    <ArtMovementsD3 sectionTitle="Art Movements" sectionSubTitle="Data Visualization" yearOfArtwork="2010" /> -->
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
// import SeparatorComponent from '@/components/SeparatorComponent.vue';
// import SubtleBreathOfLifeTwo from '@/components/SubtleBreathOfLifeTwo.vue';
// import SubtleBreathOfLifeOne from '@/components/SubtleBreathOfLifeOne.vue';
// import HomageSection from '@/components/HomageSection.vue';
// import SingleWorks from '@/components/SingleWorks.vue';
// import ArtMovementsD3 from '@/components/ArtMovementsD3.vue';

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    // SeparatorComponent,
    // ArtMovementsD3,
    // SubtleBreathOfLifeTwo,
    // SubtleBreathOfLifeOne,
    // HomageSection,
    // SingleWorks,
  },
};
</script>

<style lang="scss">
.home {
  .hero {
    height: 100vh;
  }
}
</style>
