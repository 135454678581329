<template>
  <div class="hero">
    <h1>
      <span>::</span>
      <span class="deco">||</span>
      <br class="break-if-narrow" />
      {{ headTitle }}
      <br class="break-if-narrow" />
      <span class="deco">||</span>
      <span>::</span>
    </h1>
    <div>
      <ul class="social-media">
        <li>
          <a :href="links.instagram" target="_blank">
            <InstagramIcon />
          </a>
        </li>
        <li>
          <a :href="links.vimeo" target="_blank">
            <VimeoIcon />
          </a>
        </li>
        <!-- <li>
          <a :href="links.baddstories" target="_blank">
            <img class="baddstories" src="../assets/images/baddstories.png" />
          </a>
        </li> -->
      </ul>
    </div>
  </div>
</template>

<script>
import InstagramIcon from '@/components/InstagramIcon.vue';
import VimeoIcon from '@/components/VimeoIcon.vue';

export default {
  props: {
    headTitle: {
      type: String,
      required: true,
    },
  },
  components: {
    InstagramIcon,
    VimeoIcon,
  },
  data() {
    return {
      links: {
        instagram: 'https://www.instagram.com/alejandragsarmiento',
        vimeo: 'https://vimeo.com/alejandragsarmiento',
        baddstories: 'https://vimeo.com/baddstories',
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.hero {
  background: $darkGrey;
  color: white;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 93% 7%;
  height: 100vh;
  position: relative;
  text-align: center;
  transition: all 0.4s ease-in-out;
  z-index: 1;

  h1 {
    align-self: center;
    animation: 1s ease-in-out 0s 1 slideInFromRight;
    box-sizing: border-box;
    font-size: 1.3rem;
    font-weight: 300;
    justify-self: center;
    letter-spacing: 1rem;
    line-height: 5rem;
    padding-left: 1.6rem;
    text-transform: uppercase;

    span {
      color: $strongPink;
      letter-spacing: 1.5rem;
    }

    .break-if-narrow {
      display: block;

      @media only screen and (min-width: $tbPortrait) {
        display: none;
      }
    }

    @media only screen and (min-width: $tbPortrait) {
      font-size: 1.4rem;
    }

    @media only screen and (min-width: $tbLandscape) {
      font-size: 1.6rem;
    }
  }

  // &.home-hero-title {
  //   color: $darkest;
  //   border-bottom: 1px solid white;
  // }

  .social-media {
    // align-self: end;
    // display: grid;
    // grid-template-columns: 1fr 1fr;
    // justify-self: center;

    li {
      padding: 1.2rem;
    }

    svg,
    .baddstories {
      height: 2rem;
      width: 2rem;
      vertical-align: middle;
    }
  }

  // .baddstories {
  //   width: 40px;
  //   height: 40px;
  // }
}

@keyframes slideInFromRight {
  0% {
    opacity: 0;
    transform: translate(25%);
  }
  100% {
    opacity: 1;
    transform: translate(0);
  }
}
</style>
