<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48.856 48.856">
    <path
      d="M21.796 45.541c-1.388 0-6.109-1.125-9.573-15.339-.019-.083-1.811-7.605-4.436-12.846-.018-.036-.263-.513-.765-.584-.342-.049-1.315.037-3.033 1.88-.195.209-.49.344-.756.318a1.003 1.003 0 0 1-.74-.354L.235 15.941a1 1 0 0 1 .042-1.337c.3-.313 7.377-7.658 11.859-9.711.45-.26 3.078-1.654 5.722-.414 2.445 1.148 4.02 4.119 4.68 8.833 1.002 7.99 2.921 15.142 4.221 15.776.291-.039 2.615-.665 6.503-10.431.054-.263.292-1.646-.447-2.363-.75-.728-2.475-.753-4.854-.072a1.002 1.002 0 0 1-1.251-1.178c.026-.121 2.813-12.042 14.265-11.718.17.004 4.21.143 6.461 3.508 1.997 2.984 1.886 7.421-.328 13.189-.522 1.058-12.026 23.866-24.959 25.493-.06.007-.181.025-.353.025zm-14.92-30.78c.162 0 .322.013.48.038 1.528.246 2.166 1.544 2.234 1.691 2.716 5.423 4.503 12.929 4.578 13.246 3.402 13.958 7.635 13.819 7.67 13.801 11.935-1.503 23.329-24.095 23.442-24.323 1.901-4.965 2.085-8.894.494-11.271-1.682-2.514-4.818-2.621-4.85-2.622-7.434-.186-10.629 5.663-11.75 8.567 2.289-.39 3.982-.062 5.045.979 1.658 1.624.997 4.229.968 4.339a.967.967 0 0 1-.038.115c-2.703 6.808-5.226 10.705-7.499 11.583-.783.304-1.333.159-1.56.069-.853-.334-3.677-2.579-5.534-17.4-.551-3.931-1.778-6.455-3.547-7.285-1.845-.866-3.864.33-3.884.341a1.274 1.274 0 0 1-.115.062c-3.406 1.537-8.825 6.812-10.66 8.655l.972 1.151c1.267-1.154 2.457-1.736 3.554-1.736zm20.02 14.382l.002.001-.002-.001zm-.003-.001l.002.001-.002-.001zm-.002-.001c.001 0 .001.001 0 0 .001.001.001 0 0 0z"
    />
  </svg>
</template>

<script>
export default {
  name: 'VimeoIcon',
};
</script>
