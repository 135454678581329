<template>
  <div class="container">
    <HeroSection headTitle="About" />
    <SeparatorComponent sectionTitle="Awards & Exhibitions" />
    <AwardsSection />
    <BioSection />
    <StatementSection />
    <FeaturedSection sectionTitle="Recent Features" />
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue';
import SeparatorComponent from '@/components/SeparatorComponent.vue';
import AwardsSection from '@/components/AwardsSection.vue';
import BioSection from '@/components/BioSection.vue';
import StatementSection from '@/components/StatementSection.vue';
import FeaturedSection from '@/components/FeaturedSection.vue';

export default {
  name: 'AboutView',
  components: {
    HeroSection,
    SeparatorComponent,
    AwardsSection,
    BioSection,
    StatementSection,
    FeaturedSection,
  },
};
</script>

<style lang="scss" scoped>
.container {
  .hero {
    h1 {
      display: none;

      @media only screen and (min-width: $tbPortrait) {
        max-width: unset;
      }
    }
  }
}
</style>
