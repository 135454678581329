<template>
  <header :class="{ folded: folded, isHome: isHome }">
    <div class="brand">
      <router-link to="/">
        <span></span>
      </router-link>
    </div>
    <!-- <ul class="menu">
      <li>
        <router-link to="/about">About</router-link>
      </li>
      <li>
        <router-link to="/contact">Contact</router-link>
      </li>
    </ul> -->
  </header>
</template>

<script>
export default {
  data() {
    return {
      folded: false,
      isHome: false,
    };
  },
  mounted() {
    this.detectHomePage();
    window.addEventListener('scroll', this.scrollEvent);
  },
  watch: {
    $route() {
      this.detectHomePage();
    },
  },
  methods: {
    scrollEvent() {
      let scrollPos = window.scrollY;

      if (scrollPos >= 82) {
        this.folded = true;
      } else if (scrollPos <= 82) {
        this.folded = false;
      }
    },
    detectHomePage() {
      if (this.$router.currentRoute.path === '/') {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
header {
  align-items: center;
  background: transparent;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: $darkGrey;
  display: grid;
  font-size: 1.1rem;
  font-weight: 400;
  grid-template-columns: 15% 85%;
  left: 0;
  padding: 2rem;
  position: fixed;
  text-transform: uppercase;
  top: 0;
  transition: all 0.4s ease-in-out;
  width: 100%;
  z-index: 2;

  &.folded {
    background: rgba(255, 255, 255, 0.8);
    padding: 1rem;

    a {
      color: $darkGrey;
    }

    @media only screen and (min-width: $tbPortrait) {
      padding: 1.5rem 3rem 1.5rem 3rem;
    }
  }

  // &.isHome {
  //   border-bottom: 1px solid white;

  //   a {
  //     color: $darkGrey;
  //   }
  // }

  a {
    color: white;

    &:hover {
      opacity: 0.4;
    }
  }

  @media only screen and (min-width: $mbLandscape) {
    // color: white;
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: $tbPortrait) {
    padding: 3rem;
  }
}

.brand {
  display: grid;

  span {
    align-self: center;
    background: url('../assets/images/brand.png') top center no-repeat;
    background-size: contain;
    display: block;
    justify-self: center;
    height: 3.2rem;
    width: 3.2rem;
  }

  a.router-link-active {
    opacity: 1;
  }
}

.menu {
  justify-self: end;

  li {
    &:not(:last-child) {
      padding-right: 2rem;
    }
  }
}
</style>
