<template>
  <div class="awards-block">
    <div class="awards-container">
      <div class="item" v-for="award in awards" :key="award.key">
        <h3>{{ award.name }}</h3>
        <h5>{{ award.title }}</h5>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Here we use the square brackets to group various objects, the array of awards
      // contrary to the bio section, where there is only one biography (see notes in BioBlock)
      // To access a key in the array of multiple objects we would need to indicate its
      // index position and then reference the key
      // awards[0].title
      awards: [
        {
          name: 'Art Basel Miami',
          title: 'Stories Within Images',
        },
        {
          name: 'Musée du Louvre Paris',
          title: 'The Black & White Collection by Exposure Awards',
        },
        {
          name: 'Casa Cuadrada Zurich',
          title: 'Abstractions',
        },
        {
          name: 'Marbella Film Festival',
          title: 'Official Selection | PUPS',
        },
        {
          name: 'Exposure Awards New York',
          title: 'The Black & White Collection',
        },
        {
          name: 'SCENECS Film Fest Amsterdam',
          title: 'Official Selection | PUPS',
        },
        {
          name: 'OTF Festival Baja California',
          title: 'Best Short Documentary | PUPS',
        },
        {
          name: 'Los Angeles CineFest',
          title: 'Official Selection | PUPS',
        },
        {
          name: 'Kourd Gallery Athens',
          title: "Sappho's Charriot Exhibition",
        },
        {
          name: 'Cinema Jove Valencia',
          title: 'Special Mention',
        },
        {
          name: 'Directed By Women BCN',
          title: 'Special Mention',
        },
        {
          name: 'FENALCO Gallery Bogota',
          title: 'The Abstracts',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.awards-block {
  background: $parmaPink;
  display: grid;
  padding: 5rem;

  @media only screen and (min-width: $tbPortrait) {
    padding: 10rem;
  }
}

.awards-container {
  background: white;
  display: grid;
  grid-template-columns: 1fr;
  padding: 2rem;

  @media only screen and (min-width: $mbLandscape) {
    grid-template-columns: 1fr 1fr;
  }

  @media only screen and (min-width: $tbPortrait) {
    padding: 3rem;
  }

  @media only screen and (min-width: $tbPortrait) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .item {
    padding: 1.5rem;
    text-align: center;

    h3 {
      color: $darkGrey;
    }

    h5 {
      color: $lightGrey;
    }
  }
}
</style>
