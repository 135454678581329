import Vue from 'vue';
import Router from 'vue-router';
import HomeView from './views/HomeView.vue';
import AboutView from './views/AboutView.vue';
import ContactView from './views/ContactView.vue';
import LegalView from './views/LegalView.vue';
import PageNotFound from './views/PageNotFound.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'HomeView',
      component: HomeView,
    },
    // {
    //   path: '/about',
    //   name: 'AboutView',
    //   component: AboutView,
    // },
    // {
    //   path: '/contact',
    //   name: 'ContactView',
    //   component: ContactView,
    // },
    // {
    //   path: '/legal',
    //   name: 'LegalView',
    //   component: LegalView,
    // },
    {
      path: '/*',
      name: 'PageNotFound',
      component: PageNotFound,
    },
  ],
});
